<template>
  <div class="layers-products">
    <div class="container">
      <div class="layers-products__header">
        <div class="layers-products__header-path">
          <div class="item" v-for="(item, i) in path" :key="`path-item-${i}`">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="layers-products__title major-title" v-if="response">
        {{ $t('message.productsModal.title') }}
      </div>
      <Layers-filter
        :categories="response.categories"
        :products="response.products"
        v-if="response"
        @returnFilteredProducts="showFilteredProducts"
      />
    </div>
    <Layers-products-view
      :products="productsToProductView"
      v-if="response"
      :layerIndex="layerIndex"
      :itemIndex="itemIndex"
      :placement="placement"
      :slopeType="slopeType"
      :action="action"
      :fastenerIndex="fastenerIndex"
      @close="close"
    />
    <div class="container" v-if="!response">
      <div class="error">
        <div class="layers-products__title major-title error__title">
          К сожалению для данного слоя нет материалов
        </div>
        <div class="error__subtitle">
          <div class="error__subtitle-text">слой: "{{ path[1] }}" будет удален автоматически</div>
          <SimpleButton :color="'red'" @clickedFromSimpleBtn="deleteAndClose">
            продолжить
          </SimpleButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayersFilter from '@/components/smart/layersProducts/LayersFilter'
import LayersProductsView from '@/components/smart/layersProducts/LayersProductsView'
import SimpleButton from '@/components/elements/Dom/Simple-button'
export default {
  props: {
    path: Array,
    layerIndex: Number,
    itemIndex: Number,
    placement: String,
    response: Object,
    slopeType: String,
    action: String,
    fastenerIndex: Number
  },
  components: {
    SimpleButton,
    LayersFilter,
    LayersProductsView
  },
  data: () => ({
    filteredProducts: []
  }),
  methods: {
    showFilteredProducts(products) {
      this.filteredProducts = products
    },
    close() {
      this.$emit('close', { isProductSelected: true })
    },
    deleteAndClose() {
      this.$emit('deleteAndClose', { layerIndex: this.layerIndex, itemIndex: this.itemIndex })
    }
  },
  computed: {
    productsToProductView() {
      return this.filteredProducts.length === 0 ? this.response.products : this.filteredProducts
    }
  }
}
</script>
<!--<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>
<style lang="sass">
.layers-products
  .ps__rail-y
    opacity: 1
</style>-->
<style scoped lang="sass">
.layers-products
  height: calc((var(--vh, 1vh)))
  width: 100vw
  &__header
    +media((padding: (320: rem(15) rem(16), 960: rem(22) rem(40) rem(24) 0)))
    box-shadow: inset 0 rem(-1) 0 #D6D6D6
    &-path
      display: flex
      align-items: center
      flex-direction: row
      flex-wrap: wrap
      width: calc(100% - 24px)
      .item
        @extend .fs-12
        color: $light-black
        font-weight: bold
        +media((padding: (320: 0 rem(16) 0 0, 960: 0 rem(20) 0 0)))
        +media((margin: (320: 0 rem(16) 0 0, 960: 0 rem(20) 0 0)))
        position: relative
        &:not(:last-child)
          &:after
            content: url(~@/assets/img/icons/modal--path-chevron.svg)
            position: absolute
            right: 0
            top: 50%
            transform: translateY(-50%)
        &:first-child
          padding-left: 0
        &:last-child
          padding-right: 0
          margin-right: 0
  &__title
    +media((padding: (320: rem(19) rem(16), 960: rem(19) 0)))
  .error
    &__subtitle
      +media((padding: (320: 0 rem(16), 960: 0)))
      &-text
        @extend .fs-16
      .simple-btn
        height: rem(48)
        margin-top: rem(32)
</style>
